<template>    
  <div>
    <div> 관리번호 : {{item.cJobReqIdx}} </div>
    <div> {{item.cJobTitle}} </div>
    <div> {{item.cJobName}}  </div>
    <div> {{item.cJobPartNm}} </div>
    <div> {{item.cJobPermissionPartNm}} </div>    
    <v-chip class="mt-1 mb-1" v-if="item.cIsChemicalUniform == 'Y'" label small color="red lighten-1" dark>내화학복 O</v-chip>
    <v-chip class="mt-1 mb-1" v-if="item.cIsChemicalUniform == 'N'" label small color="grey darken-3" dark>내화학복 X</v-chip>
  </div>
</template>

<script>


export default {
  components: {        
  },
  props: ['item'],  
  data() {
    return {            
    };
  },  
  created() {    
  },
  

  mounted () {    
  },
  methods: {    
  }
};

</script>
