<template>  
  <div>
    <div class="background_basic">      
      <div style="height:1px"></div>
      <v-card class="ma-4">
        <v-toolbar dense flat color="white">
          <v-toolbar-title class="grey--text text--darken-3">
            <v-icon class="mr-2">mdi-card-account-details-star-outline</v-icon>대리출입신청
          </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn color="blue" text @click="initialize()" class="font-weight-bold text-subtitle-1">
            <v-icon big class="mr-2">refresh</v-icon>초기화
          </v-btn>
          <v-btn color="blue" text @click="ExcelExpert('AP')" class = "ml-2 font-weight-bold text-subtitle-1">
            <v-icon big class="mr-2">mdi-microsoft-excel</v-icon>엑셀
          </v-btn>
          <v-spacer></v-spacer>
          <!-- 가장오른쪽에 신규등록 다이올로그 추가, 수정이 가능한 id접속시에만 보여짐 --> 
          <!-- 수정 or 신규 등록 다이올로그, 필수항목 기입안했을시 알람표시, 미기입시 항목초기화 버튼생성 -->  
          <v-btn color="blue" text @click="GetSHEJobFunc()" class="font-weight-bold text-subtitle-1" :disabled="loading3">
            <v-icon big class="mr-2">mdi-cloud-download</v-icon>업체정보
          </v-btn>
          <v-dialog v-model="dialog" max-width="600px">
            <template v-slot:activator="{ on }">
              <v-btn color="blue" text v-on="on" :disabled="!ProgramPermissionInfo.okC" @click="ClearDialog()" class="font-weight-bold text-subtitle-1">
                <v-icon big class="mr-2">post_add</v-icon>일반출입신청
              </v-btn>
            </template>
            <v-card ref="form">
              <v-card-title >
                <v-card-title >
                  <span v-if="isJobMode" class="font-weight-bold">작업출입신청</span>
                  <span v-else class="font-weight-bold">일반출입신청</span>
                </v-card-title>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row class="ma-0">
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field persistent-placeholder dense placeholder="관리번호" v-model="editedItem.cIdx" label="관리번호" disabled></v-text-field>
                    </v-col>
                    
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field v-if="isJobMode || isEditMode" dense v-model="editedItem.cReqDateTime" label="출입일자" placeholder=""  Regular persistent-placeholder disabled ></v-text-field>    
                      <eCalendar v-else :sNowSelectDate="editedItem.cReqDateTime" Caption="출입일자" @onChaange="onCalChange"></eCalendar> 
                    </v-col>
                    <v-col v-if="isJobMode" cols="12" sm="6" md="6">
                      <v-text-field dense v-model="editedItem.cJobName" label="작업공정명" placeholder="작업공정명"  Regular persistent-placeholder disabled ></v-text-field>                        
                    </v-col>
                    <v-col v-if="isJobMode" cols="12" sm="6" md="6">
                      <v-text-field dense v-model="editedItem.cJobTitle2" label="작업허가명" placeholder="작업허가명"  Regular persistent-placeholder disabled ></v-text-field>                        
                    </v-col>
                    <v-col v-if="isJobMode" cols="12" sm="6" md="6">
                      <v-text-field dense v-model="editedItem.cJobPermissionPartNm" label="허가부서명" placeholder="허가부서명"  Regular persistent-placeholder disabled ></v-text-field>                        
                    </v-col>
                    <v-col v-if="isJobMode" cols="12" sm="6" md="6">
                      <v-text-field dense v-model="editedItem.cJobPartNm2" label="수행부서명" placeholder="수행부서명"  Regular persistent-placeholder disabled ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12" md="12" >             
                      <v-text-field persistent-hint :hint="'선택 ' + editedItem.zUserId.length + '명'" @click="MemberSelectClik()"  @click:append="onClearUser()" append-icon="mdi-close" dense v-model="editedItem.cUserNms" label="인원선택" placeholder="인원을 선택하세요."  Regular persistent-placeholder readonly>                      
                      </v-text-field>                    
                    </v-col>                  
                    <v-col cols="12" sm="12" md="12" >
                      <v-text-field :disabled="editedItem.isNoMap" dense readonly label="출입공정" v-model="editedItem.cMapNm" append-icon="mdi-close" @click:append="onNowLocationDel" @click="selectMap" placeholder="위치를 선택하세요."></v-text-field>                    
                      <!-- <v-checkbox dense label="출입공정 모름" v-model="editedItem.isNoMap" @change="onNoMap"></v-checkbox> -->
                    </v-col>
                    
                    <v-col v-if="!isJobMode" cols="12" sm="12" md="12" >
                      <v-textarea              
                        v-model="editedItem.cReqMessage"
                        label="출입목적"            
                        placeholder="상세히 기술하세요."    
                        persistent-placeholder
                        hint="상세히 기술하세요."
                        class="mt-n5"
                        outlined
                        color="black"
                        no-resize
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>                              
                <v-spacer></v-spacer>
                <v-btn color="blue darken-2" text @click="save" :disabled="loading" class="font-weight-bold text-subtitle-1">
                  <v-icon big class="mr-2">save</v-icon>저장
                </v-btn>
                <v-btn color="grey darken-2" text @click="close" :disabled="loading" class="font-weight-bold text-subtitle-1">
                  <v-icon big class="mr-2">cancel</v-icon>취소
                </v-btn>
              </v-card-actions>              
              <v-progress-linear :active="loading" :indeterminate="loading" absolute bottom color="blue accent-4"></v-progress-linear>
            </v-card>
          </v-dialog>

          <v-btn color="blue" text  @click="GetJobInfo()" class="font-weight-bold text-subtitle-1">
            <v-icon big class="mr-2">mdi-account-hard-hat</v-icon>작업출입신청
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>     
        
        <v-card flat class="d-flex ml-3 mr-3">                
          <v-card flat class="ml-3 mb-1">             
            <IronDate ref="IronDateRef" v-model="sNowSelectDate" @update="onDateRangeChange"/>
          </v-card>                        
          <v-card class="ml-3 mb-1" flat style="max-width:150px;">   
            <v-select v-model="cEnterType" class="mb-n5" label="유형" :items="zEnterType" item-value="sId" item-text="sNm"></v-select>   
          </v-card>                     
          <v-card class="ml-3 mb-1" flat style="max-width:150px;">   
            <v-select v-model="cResult" class="mb-n5" label="상태" :items="zReqStateTypeAll" item-value="sId" item-text="sNm"></v-select>   
          </v-card>           
          <v-text-field
            v-model="cNowSearchKeyWord"
            class="ml-3 mb-n5"
            label="검색어"
            placeholder="관리번호 or Id or Mac 입력"
            persistent-placeholder
            style="max-width:250px;"
            Regular
            v-on:keyup.enter="Search()"
          ></v-text-field>
          
          <v-btn icon class="ml-n8 mt-3 text-subtitle-1" @click="Search()">
            <v-icon>search</v-icon>
          </v-btn>
        </v-card>
      </v-card>

      <v-data-table 
      class="ma-4 elevation-3"
        :items-per-page="-1" 
        fixed-header 
        height="calc(100vh - 227px)" 
        :headers="headers" 
        :items="Records"
        @dblclick:row="handleDoubleClick"
        >
        <template v-slot:item.action="{ item }">
          <!-- <v-icon big class="mr-0" @click="editItem(item)" :disabled="!ProgramPermissionInfo.okU || item.cResult != 'REQ' ">edit</v-icon> -->
          <v-icon big @click="deleteItem(item)" :disabled="!ProgramPermissionInfo.okD || item.cResult != 'REQ' ">delete</v-icon>          
        </template>

        <template v-slot:item.cResult="{ item }">
          <v-chip label small v-if ="item.cResult == 'REQ'" color="green lighten-2" dark>신청중</v-chip>
          <v-chip label small v-else-if ="item.cResult == 'OK'" color="blue lighten-3" dark>승인</v-chip>
          <v-chip label small v-else-if ="item.cResult == 'NG'" color="red lighten-3" dark>반려</v-chip>
          <v-chip label small v-else-if ="item.cResult == 'HOLD'" color="black" dark>보류</v-chip>
          <v-chip label small v-else color="grey lighten-3" >{{item.cResult}}</v-chip>
        </template>
        <template v-slot:item.cMapNm="{ item }">
          <v-chip label small v-if ="item.cMapNm == ''" color="red lighten-2" dark>출입공정 모름</v-chip>
          <div label small v-else color="grey lighten-3" >{{item.cMapNm}}</div>
        </template>
        <template v-slot:item.cType="{ item }">
          <v-chip label small v-if ="item.cType == 'G'" color="blue lighten-2" dark>일반</v-chip>
          <v-tooltip v-else-if ="item.cType == 'J'" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip label small v-bind="attrs" v-on="on" color="green lighten-3" dark>작업</v-chip>
            </template>            
            <JobInfoWindow :item="item"></JobInfoWindow>
          </v-tooltip>
          <v-chip label small v-else color="grey lighten-3" >{{item.cType}}</v-chip>
        </template>

        <template v-slot:item.cOKUserNm="{ item }">
          <div v-if ="item.cOKUserNm != 'NONE'">{{item.cOKUserNm}}</div>          
        </template>
        <template v-slot:no-data>
          <p>No data available.</p>
        </template>
      </v-data-table>
    
    <MsgBox ref="MsgBoxRef" @onMsgBox="onMsgBoxFunc" />    
    <GetSelMap2 ref="GetSelMapRef" @onGetMap="onGetMap" />      
    <JobSelect ref="JobSelectRef" @onSelect="onSelectJob" />    
    <MemberSelect ref="MemberSelectRef" @onSelect="onSelectMember" />
    </div>
  </div>
</template>

<script>
import MsgBox from "../components/MsgBox.vue";
import BasicInfo from "../BasicInfo.js";
import Util from "../Util.js";
import axios from "axios";
import { mapState } from "vuex";
import XLSX from 'xlsx';
import EventBus from '@/eventBus.js';
import IronDate from '../components/iron28Calendar/src/IronDate.vue'
import GetSelMap2 from "@/components/GetSelMap2.vue";
import eCalendar from "@/components/eCalendar.vue";
import JobInfoWindow from "@/components/JobInfoWindow.vue";
import JobSelect from "@/components/JobSelect.vue";
import MemberSelect from "@/components/MemberSelect.vue";

export default {
  components: {
    MsgBox,
    IronDate,    
    GetSelMap2,    
    eCalendar,
    JobSelect,
    JobInfoWindow,
    MemberSelect,
  },
  data: () => ({    
    loading3 : false,
    isUpdating: false,   
    isJobMode : false, 
    people: [],    
    sNowSelectDate :  Util.sFormatDate(Date()) + " ~ " +  Util.sFormatDate(Util.sTomorrowSel()),
    sStartDate : Util.sFormatDate(Date()),
    sEndDate :Util.sFormatDate(Util.sTomorrowSel()),
    cNowSearchKeyWord : "",    
    cNowCheck: "A",
    cResult : "ALL",    
    cEnterType : "ALL",    
    loading : false,
    isEditMode : false,
    iDelIndex: 0,
    dialog: false,    
    menu : false,  
    zSelLocation : [],
    headers: [      
      { class: "font-weight-bold subtitle-2", text: "관리번호", value: "cIdx", align: "center", width:"100" },      
      { class: "font-weight-bold subtitle-2", text: "출입일", value: "cReqDateTime", align: "center", width:"150"},
      { class: "font-weight-bold subtitle-2", text: "유형", value: "cType", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "출입목적", value: "cReqMessage", align: "center",  width:"400"},
      { class: "font-weight-bold subtitle-2", text: "상태", value: "cResult", align: "center" },      
      { class: "font-weight-bold subtitle-2", text: "내용", value: "cMessage", align: "center", width:"150"},
      { class: "font-weight-bold subtitle-2", text: "신청자", value: "cReqUserNm", align: "center", width:"100"},
      { class: "font-weight-bold subtitle-2", text: "승인자", value: "cOKUserNm", align: "center", },
      { class: "font-weight-bold subtitle-2", text: "승인일시", value: "cOKDateTime", align: "center", width:"120"},
      { class: "font-weight-bold subtitle-2", text: "소속", value: "cCorpNm", align: "center", width:"100"},
      { class: "font-weight-bold subtitle-2", text: "작업자", value: "cUserNms", align: "center", width:"250"},
      { class: "font-weight-bold subtitle-2", text: "구역", value: "cMapNm", align: "center", width:"250"},
      { class: "font-weight-bold subtitle-2", text: "작업", value: "action", align: "center", sortable: false},
    ],
    Exheaders: [      
      { class: "font-weight-bold subtitle-2", text: "관리번호", value: "cIdx", align: "center", width:"100" },      
      { class: "font-weight-bold subtitle-2", text: "출입일", value: "cReqDateTime", align: "center", width:"150"},
      { class: "font-weight-bold subtitle-2", text: "유형", value: "cTypeNm", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "출입목적", value: "cReqMessage", align: "center",  width:"400"},
      { class: "font-weight-bold subtitle-2", text: "상태", value: "cResult", align: "center" },      
      { class: "font-weight-bold subtitle-2", text: "내용", value: "cMessage", align: "center", width:"150"},
      { class: "font-weight-bold subtitle-2", text: "신청자", value: "cReqUserNm", align: "center", width:"100"},
      { class: "font-weight-bold subtitle-2", text: "승인자", value: "cOKUserNm", align: "center", },
      { class: "font-weight-bold subtitle-2", text: "승인일시", value: "cOKDateTime", align: "center", width:"120"},
      { class: "font-weight-bold subtitle-2", text: "소속", value: "cCorpNm", align: "center", width:"100"},
      { class: "font-weight-bold subtitle-2", text: "작업자", value: "cUserNms", align: "center", width:"250"},
      { class: "font-weight-bold subtitle-2", text: "구역", value: "cMapNm", align: "center", width:"250"},
      { class: "font-weight-bold subtitle-2", text: "작업", value: "action", align: "center", sortable: false},
    ],
    Records: [],
    editedIndex: -1,
    
    editedItem: { 
      cIdx: "",
      cReqDateTime : Util.sFormatDate(Date()),
      cUserCount : "0",
      zUserId : [],      
      zTagId : [],      
      zMap : [],
      cType: "",
      cReqMessage: "",
      cResult: "",
      cMessage: "",
      cReqUserNm: "",
      cOKUserNm: "",
      cUserNms: "",
      cMapNm: "",
      isNoMap : false,
      cCorpId : "",
      cJobReqIdx : "NONE",      
      cJobTitle : "",      
      cJobPermissionPartNm : "",      
      cJobPartNm : "",
    },
    deletedItem: {
      cIdx: "",
      cReqDateTime : Util.sFormatDate(Date()),
      cUserCount : "0",
      zUserId : [],
      zTagId : [],      
      zMap : [],
      cType: "",
      cReqMessage: "",
      cResult: "",
      cMessage: "",
      cReqUserNm: "",
      cOKUserNm: "",
      cUserNms: "",
      cMapNm: "",
      isNoMap : false,
      cCorpId : "",
      cJobReqIdx : "NONE",      
      cJobTitle : "",      
      cJobPermissionPartNm : "",      
      cJobPartNm : "",
    },
    defaultItem: {
      cIdx: "",
      cReqDateTime : Util.sFormatDate(Date()),
      cUserCount : "0",
      zUserId : [],
      zTagId : [],      
      zMap : [],
      cType: "",
      cReqMessage: "",
      cResult: "",
      cMessage: "",
      cReqUserNm: "",
      cOKUserNm: "",
      cUserNms: "",
      cMapNm: "",
      isNoMap : false,
      cCorpId : "",
      cJobReqIdx : "NONE",      
      cJobTitle : "",      
      cJobPermissionPartNm : "",      
      cJobPartNm : "",
    }
  }),

  computed: {
    ...mapState(["UserInfo", "ProgramPermissionInfo", "zReqStateTypeAll", "zEnterType", ]),    
  },

  mounted() {
    this.initialize();
  },

  methods: {    
    onCalChange(s){      
      this.editedItem.cReqDateTime = s;      
    },    
    GetSHEJobFunc(){
    var posData = {}
      
      this.loading3 = true;
      axios
        .post(BasicInfo.UIL_API + "GetSHEJobFunc", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {          
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {            
            EventBus.$emit("onShowMsg",false, "불러오기를 성공하였습니다.");
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }          
          this.loading3 = false;            
        })
        .catch(err => {
          this.loading3 = false;
          EventBus.$emit("onShowMsg",true, ""+err);          
          
        });
    } ,   
    initialize() {
      this.cNowSearchKeyWord = "";
      this.cResult = "ALL";
      this.cEnterType = "ALL";

      this.sNowSelectDate = Util.sFormatDate(Date()) + " ~ " +  Util.sFormatDate(Util.sTomorrowSel());   
      this.$refs.IronDateRef.SetDate(this.sNowSelectDate);
      this.sStartDate = Util.sFormatDate(Date());
      this.sEndDate = Util.sFormatDate(Util.sTomorrowSel());      
      this.Search();    
    },

    MemberSelectClik(){
      this.$refs.MemberSelectRef.Show("", "");      
    },

    isAddMember(cId) {
      for(var i = 0; i < this.editedItem.zUserId.length; i++){
        if (this.editedItem.zUserId[i] == cId) {
          return false;
        }        
      }
      return true;
    },

    onSelectMember(data){
      
      for(var i = 0; i < data.length; i++){
        if (this.isAddMember(data[i].cUserId)) {
          this.editedItem.zUserId.push(data[i].cUserId);
          this.editedItem.zTagId.push(data[i].cTagId);
          if (this.editedItem.cUserNms != "") {
            this.editedItem.cUserNms = this.editedItem.cUserNms + ",";
          }
          this.editedItem.cUserNms = this.editedItem.cUserNms + data[i].cUserNm;
          this.editedItem.cCorpId = data[i].cCorpId;            
          this.editedItem.cCorpNm = data[i].cCorpNm;            
        }        
      }      
    },

    inputChanged() {
        this.$refs.cbUserRef.blur()
        setTimeout(() => {
            this.$refs.cbUserRef.focus()
        }, 500);
    },

    remove (item) {
      const index = this.editedItem.zUserId.indexOf(item.id)
      if (index >= 0) this.editedItem.zUserId.splice(index, 1)
    },

    onGetUser(){
      this.$refs.MemberSelectRef.Show("N", "");      
    },
    
    onClearUser(){
      this.editedItem.zUserId = [];      
      this.editedItem.zTagId = [];
      this.editedItem.cUserNms = "";
    },

    onNoMap(data){
      if (data) {
        this.onNowLocationDel();    
      }
    },

    onNowLocationDel() {
      this.editedItem.cMapNm = "";      
      this.editedItem.zMap = [];
      this.editedItem.zSelectMap = [];      
    },
    selectMap () {
      this.$refs.GetSelMapRef.show(this.editedItem.zMap); 
    },

    onGetMap(zSelect, cMapNm) {      
      var posData = {        
        zMapId : zSelect,
      };
      axios
        .post(BasicInfo.UIL_API + "CheckReqEnterMap", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {
            this.editedItem.zMap = zSelect;
            this.editedItem.cMapNm = cMapNm;
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
        });     
    },

    onDateRangeChange(daterange){            
      this.sStartDate = Util.sFormatDate(daterange.startDate);
      this.sEndDate = Util.sFormatDate(daterange.endDate);      
      this.sNowSelectDate = this.sStartDate + " ~ " +  this.sEndDate;           
    },

    ClearDialog(isJobMode) {      
      this.isEditMode = false;
      this.isJobMode = isJobMode;
      
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedItem.zUserId = [];
      this.editedItem.zTagId = [];
      this.editedIndex = -1;                      
    },


    
    handleDoubleClick(value, items) { 
       if (!this.ProgramPermissionInfo.okU) return;
       if (items.item.cResult != 'REQ') return;
       this.editItem(items.item);  
    },

    
    Search() {
      this.Records = [];
      var posData = {
        cStartDatetime: this.sStartDate + " 00:00:00",  
        cEndDatetime  : this.sEndDate + " 23:59:59",
        cResult: this.cResult,
        cEnterType: this.cEnterType,
        cNowSearchKeyWord : this.cNowSearchKeyWord,
        zMapId : this.UserInfo.zMapIdAll,
      };
      axios
        .post(BasicInfo.UIL_API + "GetEnterReqLog", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {
            this.Records = res.data.Result1;         
            for (let i = 0; i < res.data.Result1.length; i++){
              this.Records[i].zUserId = [];
              this.Records[i].zUserId = res.data.Result1[i].cUserIds.split(",");
              this.Records[i].zMap = [];

              for (let j = 0; j < res.data.Result2.length; j++){
                if (res.data.Result2[j].cIdx == this.Records[i].cIdx) {
                  this.Records[i].cMapNm = res.data.Result2[j].cMapNm;
                  this.Records[i].zMap = res.data.Result2[j].cMapId.split(",");
                  break;
                }
              }
            }  
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
        });
    },



    save() {
      if (this.editedItem.zUserId.length == 0) {
         EventBus.$emit("onShowMsg",true,  "신청인원을 선택해주세요.");
        return;
      }

      if (this.editedItem.zMap.length == 0) {
      // if (!this.editedItem.isNoMap && this.editedItem.zMap.length == 0) {
        EventBus.$emit("onShowMsg",true,  "출입지역을 선택해주세요.");
        return;
      }

      if (this.editedItem.cCorpId == "") {
        EventBus.$emit("onShowMsg",true,  "회사정보를 알 수 없습니다.");
        return;
      }

      var posData = {
        cUserId: this.$store.state.UserInfo.UserId,              
        cIdx: "",
        cReqDateTime: this.editedItem.cReqDateTime,
        zUser : this.editedItem.zUserId,
        zTag : this.editedItem.zTagId,
        zMapId : this.editedItem.zMap,
        cReqMessage : this.editedItem.cReqMessage,
        cCorpId : this.editedItem.cCorpId,
        cType : "G",
        cJobReqIdx : this.editedItem.cJobReqIdx,       
        cJobTitle : this.editedItem.cJobTitle,       
				cJobName : this.editedItem.cJobName,       
				cJobPermissionPartNm : this.editedItem.cJobPermissionPartNm,       
				cJobPartNm : this.editedItem.cJobPartNm,  
      }

      

      if (this.isEditMode) {
        posData.cIdx = this.editedItem.cIdx;      
      }

      if (this.isJobMode) {
        posData.cType = "J";
      } else {
        posData.cJobReqIdx = "NONE"; 
        posData.cJobTitle = "";
				posData.cJobName = "";
				posData.cJobPermissionPartNm = "";
				posData.cJobPartNm = "";
      }

      this.editedItem.cType = posData.cType;            

      
      // for (let i = 0; i < this.editedItem.zUserId.length; i++ ){
      //   let isCheck = false;
      //   for (let j =0; j < this.people.length; j++){
      //     if (this.editedItem.zUserId[i] == this.people[j].id) {            

      //       isCheck = true;
      //       posData.zTag.push(this.people[j].tagId);

      //       if (this.editedItem.cUserNms != "") {
      //         this.editedItem.cUserNms = this.editedItem.cUserNms + ",";
      //       }
      //       this.editedItem.cUserNms = this.editedItem.cUserNms + this.people[j].name;
      //       break;
      //     }
      //   }

      //   if (!isCheck) {
      //     posData.zTag.push('NONE');
      //   }
      // }

      // console.log(posData);      
      this.loading = true;
      axios
        .post(BasicInfo.UIL_API + "ReqEnterReqLog", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {            
            this.editedItem.cResult = "REQ";
            if (posData.cType == "J") {
              this.editedItem.cResult = "OK";
            }
            this.editedItem.cReqUserNm = this.$store.state.UserInfo.UserNm;
            this.editedItem.cOKUserNm = 'NONE';            

            if (this.isEditMode) {
              this.editedItem.cIdx = res.data.Result[0].cIdx;
              this.editedItem.cUpdateDateTime = res.data.Result[0].cUpdateDateTime;
              Object.assign(this.Records[this.editedIndex], this.editedItem);
            } else {
              this.editedItem.cIdx = res.data.Result[0].cIdx;
              
              this.editedItem.cCreateDateTime = res.data.Result[0].cUpdateDateTime;
              this.editedItem.cUpdateDateTime = res.data.Result[0].cUpdateDateTime;
              this.Records.push(this.editedItem);
            }
            this.dialog = false;
            EventBus.$emit("onShowMsg",false, "저장되었습니다.");
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
          this.loading = false;
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
          this.loading = false;
        });
    },
    
    close() {      
      this.dialog = false;
      this.isEditMode = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    
    editItem(item) {
      console.log(item);
      //this.isEditMode = true;
      //this.editedIndex = this.Records.indexOf(item);   
      //this.editedItem = Object.assign({}, item);       
      //this.dialog = true; 
    },    
    deleteItem(item) {
      this.iDelIndex = this.Records.indexOf(item);
      this.deletedItem = Object.assign({}, item);
      this.$refs.MsgBoxRef.RunMsgBoxShow(
        "확인",
        "삭제하시겠습니까? \r\n 삭제하시면 복원되지 않습니다. \r\n[" +
          this.deletedItem.cIdx +
          "]"
      );
    },

    
    onMsgBoxFunc: function(result) {
      if (result === true) {
        //삭제
        var posData = {
          cUserId: this.$store.state.UserInfo.UserId,
          cJobReqIdx: this.deletedItem.cJobReqIdx,
          cIdx: this.deletedItem.cIdx,
        };

        axios
          .post(BasicInfo.UIL_API + "DelEnterReqLog", posData, {
            headers: {
              Authorization: this.$store.state.UserInfo.Token
            }
          })
          .then(res => {
            if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
            if (res.data.ResultCd === "00") {
              this.Records.splice(this.iDelIndex, 1); 
            } else {
              EventBus.$emit("onShowMsg",true, res.data.Msg);
            }
          })
          .catch(err => {
            EventBus.$emit("onShowMsg",true, ""+err);
          });
      }
    },
    
    ExcelExpert(name){
      for (let i = 0; i < this.Records.length; i++){
        if (this.Records[i].cType == "G") {
          this.Records[i].cTypeNm = "일반";
        } else if (this.Records[i].cType == "J") {
          this.Records[i].cTypeNm = "작업";        
        }  
      }      
      var r = Util.GetExcel(this.Records, this.Exheaders);
      var animalWS = XLSX.utils.json_to_sheet(r)       
      var wb = XLSX.utils.book_new() // make Workbook of Excel
      XLSX.utils.book_append_sheet(wb, animalWS, name) // sheetAName is name of Worksheet      
      XLSX.writeFile(wb, name + "-" + Util.sNowDate() + '.xlsx'); // name of the file is 'book.xlsx'
    },

    GetJobInfo(){            
      this.$refs.JobSelectRef.Show("", "");
    },

    onSelectJob(Data){
      console.log(">>>>>>>>>>>>>>>>>>", Data);
      this.ClearDialog(true);
      this.editedItem.cJobReqIdx = Data.cIdx;
      this.editedItem.cReqDateTime = Data.cDate;      
      this.editedItem.cJobTitle2 = Data.cJobTitle;
      this.editedItem.cJobName = Data.cJobName;
      this.editedItem.cJobPermissionPartNm = Data.cJobPermissionPartNm;
      this.editedItem.cJobPartNm2 = Data.cJobPartNm;
      this.editedItem.cReqMessage = Data.cJobTitle;

      this.dialog = true;
    },
  }
};
</script>

