<template>    
  <v-dialog v-model="dialog" max-width="1920">
    <v-card style="height:calc(100vh - 200px)">              
      <v-toolbar dense flat color="white">
        <v-toolbar-title class="grey--text text--darken-3">
          작업선택
        </v-toolbar-title>          
        <v-divider class="mx-4" inset vertical></v-divider>          
        협력회사명 : {{ UserInfo.CorpNm }}
      </v-toolbar>
      <v-divider></v-divider>
      <v-toolbar flat color="white" class="mb-2">                
        <!-- <div class="DateBox"> -->
          <IronDate class="DateBox ml-2"  ref="IronDateRef" v-model="sNowSelectDate" @update="onDateRangeChange"/>
        <!-- </div> -->
        
        <v-text-field
          v-model="cNowSearchKeyWord"
          class="ml-2 mt-12 mb-2  "  
          label="검색"
          placeholder="업체명 또는 작업명 또는 작업허가부서 또는 작업수행부서를 입력하세요."            
          Regular
          autofocus
          persistent-placeholder
          ref="NowSearchKeyWordRef"
          v-on:keyup.enter="Search()"
        ></v-text-field>        
        <v-btn  text icon class="ml-n9" @click="Search()"            
        ><v-icon>search</v-icon>
        </v-btn>  
      </v-toolbar>
      <v-divider></v-divider>      
      <v-data-table :items-per-page="-1" 
        v-model="selected"
        :headers="headers" 
        :items="Records" 
        single-select
        item-key="cIdx"      
        @click:row="handleClick"            
        show-select        
        height="calc(100vh - 420px)"
        class="boxTable"
      >            
        <template v-slot:item.cResult="{ item }">
          <v-chip v-if="item.cResult == 'REQ'" color="blue darken-4" label small dark>요청</v-chip>
          <v-chip v-if="item.cResult == 'WAIT'" color="grey lighten-3" label small>대기</v-chip>
        </template>
        <template v-slot:item.data-table-select="{ isSelected }">
          <v-icon class="ml-4 mr-n10" v-if="isSelected">done</v-icon> <!-- 가장 왼쪽컬럼에 V체크표시 -->             
        </template>
        <template v-slot:no-data>
          <p>No data available.</p>
        </template>
      </v-data-table>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer> 
        <v-btn color="blue darken-1" text @click="SelectData()"
          ><v-icon class="mr-2">check_circle</v-icon>선택</v-btn>        
        <v-btn color="grey darken-2" text @click="closePage()"
          ><v-icon class="mr-2">mdi-close</v-icon>닫기</v-btn>
      </v-card-actions>
    </v-card>    
  </v-dialog>  
</template>

<script>
import Util from "../Util.js";
import axios from "axios";
import BasicInfo from "@/BasicInfo.js";
import EventBus from '@/eventBus.js';
// import eCalendar from "@/components/eCalendar.vue";
import { mapState } from "vuex";
import IronDate from '../components/iron28Calendar/src/IronDate.vue'

export default {
  components: {    
    // eCalendar,
    IronDate,
  },
  data() {
    return {      
      cCorpId : "",
      cCorpNm : "",
      sNowSelectDate :  Util.sFormatDate(Date()) + " ~ " +  Util.sFormatDate(Util.sTomorrowSel()),
      sStartDate : Util.sFormatDate(Date()),
      sEndDate :Util.sFormatDate(Util.sTomorrowSel()),
      dialog: false,      
      model : null,      
      sDate : "",      
      selected: [],
      cNowSearchKeyWord : "",
      headers: [        
        { class: "font-weight-bold subtitle-2",  text: "순번", value: "cIdx", width: 120 },
        { class: "font-weight-bold subtitle-2",  text: "일자", value: "cDate", width: 120 },        
        { class: "font-weight-bold subtitle-2",  text: "작업공정", value: "cJobName", width: 120 },
        { class: "font-weight-bold subtitle-2",  text: "상태", value: "cResult", width: 50 },
        { class: "font-weight-bold subtitle-2",  text: "업체명", value: "cCorpNm", width: 100 },
        { class: "font-weight-bold subtitle-2",  text: "작업허가명", value: "cJobTitle", width: 250 },      
        { class: "font-weight-bold subtitle-2",  text: "작업허가부서", value: "cJobPermissionPartNm", width: 120 },      
        { class: "font-weight-bold subtitle-2",  text: "작업수행부서", value: "cJobPartNm", width: 120 },        
      ],
      Records: [],
    };
  },  
  created() {
    this.dialog = false;
  },
  watch: {
    dialog(val) {
      val || this.closePage();
    }
  },

  computed: {
    ...mapState(["UserInfo"]),
    
  },
  mounted () {    
    this.sDate = Util.sNowDate2();
    console.log( this.sDate);
  },
  methods: {    
    onDateRangeChange(daterange){            
      this.sStartDate = Util.sFormatDate(daterange.startDate);
      this.sEndDate = Util.sFormatDate(daterange.endDate);      
      this.sNowSelectDate = this.sStartDate + " ~ " +  this.sEndDate;           
    },
    closePage: function() {                
      this.dialog = false;
    },
    getColor(cSendFlag) {
      if (cSendFlag === "N") return "#ff4862";
      else return "#70bbfe";
    },    

    getColor2(cSendFlag) {
      if (cSendFlag === "Y") return "#ff4862";
      else return "#70bbfe";
    },    
    Show: function(cCorpId, cCorpNm) {             
      this.selected = [];
      this.Records = [];      
      this.cNowSearchKeyWord = "";
      this.cCorpId = cCorpId;
      this.cCorpNm = cCorpNm;
      this.dialog = true;      

      setTimeout(() => {
        this.Search();  
      }, 300);      
    },
    SelectData(){
      if (this.selected.length == 0) {
        EventBus.$emit("onShowMsg",true, "No data selected.") 
        return;
      }      
      this.$emit("onSelect", this.selected[0]);
      this.dialog = false;   
    },    
    handleClick(value) {      
      this.selected = [value];
    },
    onUpdateDate(date) {            
      this.sDate = date;
      this.Search();
    },
    Search(){  
      this.Records = [];

      var posData = {
        cUserId: this.$store.state.UserInfo.UserId,
        cNowSearchKeyWord : this.cNowSearchKeyWord,                
        cCorpId : this.cCorpId,        
        cCorpNm : this.cCorpNm,        
        // cDate : this.sDate,     
        cStartDate: this.sStartDate,  
        cEndDate  : this.sEndDate,
      };

      axios
        .post(BasicInfo.UIL_API + "GetJobReqLog", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {
            this.Records = res.data.Result;
            for(var i = 0; i < this.Records.length; i++){
              this.Records[i].bUse = this.Records[i].cUse == "Y";              
            }   
            
            if (this.Records.length > 0) {
              this.selected = [this.Records[0]];
            }
            
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);          
        });
    },
  }
};
</script>

<style scoped>
  .DateBox {
    z-index: 99999999;    
    height:50px; 
    margin-top:10px; 
    padding-top:13px;
  }  
  
</style>
