<template>
  <v-row justify="center" class="ma-0">
    <v-dialog v-model="bDialog" max-width="400px"  v-if="bDialog">
      <v-card >
        
        <v-card-title >
          <span class="font-weight-bold">구역정보 선택</span>
        </v-card-title>
        <v-divider></v-divider>
          <div style="overflow-x: auto; overflow-y: auto; width:100%; height:calc(100vh - 500px); padding:10px; background-color:#ffffff">
            <v-treeview     
                v-model="selection"               
                selection-type="leaf"
                :items="items"
                selectable
                item-key="id"
                hoverable                
                dense                  
                :open.sync="open">
                <!-- <template v-slot:prepend="{ item }">
                  <v-icon v-if="item.iLev == -1 ">mdi-map</v-icon>
                  <v-icon v-else-if="item.iLev == 0 ">mdi-map</v-icon>
                  <v-icon v-else-if="item.iLev == 1 ">mdi-group</v-icon>
                  <v-icon v-else-if="item.iLev == 2 ">mdi-office-building</v-icon>
                  <v-icon v-else>mdi-floor-plan</v-icon>
                </template> -->
            </v-treeview>
          </div>
        <v-divider></v-divider>
        <v-card-actions>           
          <v-spacer></v-spacer>
          <v-btn color="blue darken-2" text @click="save" class="font-weight-bold text-subtitle-1">
            <v-icon big class="mr-2">save</v-icon>확인
          </v-btn>
          <v-btn color="grey darken-2" text @click="bDialog=false" class="font-weight-bold text-subtitle-1">
            <v-icon big class="mr-2">cancel</v-icon>취소
          </v-btn>          
        </v-card-actions>              
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import EventBus from '@/eventBus.js';
import BasicInfo from "../BasicInfo.js";
import axios from "axios";


export default {
  data: () => ({
    bDialog: false,   
    items: [],    
    zResult: [],    
    zIniSel : [],
    zAllData : [],
    selection : [],
    zRealData: [],   
  }),

  created() {
    this.bDialog = false;
  },

  methods: {
    Search() {         
      this.open = [];   
      this.items = [];
      this.selection = [];
      this.zResult = [];
      this.sNowMapId = "";
      this.zApPos = [];
      this.imgUrl = BasicInfo.FILE_API + "Files/NoImage.svg";

      var posData = {        
      };
      axios
        .post(BasicInfo.UIL_API + "GetMapInfoOnlySel", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {         
            this.zResult = res.data.Result;
            for (let i = 0; i < res.data.Result.length; i++) {
              if (res.data.Result[i].iLev == 0) {
                let item = {
                  id: res.data.Result[i].cId,
                  cMapGroupId: res.data.Result[i].cMapGroupId,
                  cBuildingId: res.data.Result[i].cBuildingId,
                  cMapId: res.data.Result[i].cMapId,
                  name: res.data.Result[i].cMapNm,
                  cMapFullNm: res.data.Result[i].cMapFullNm,
                  file: res.data.Result[i].cFileId,
                  iLev: res.data.Result[i].iLev,
                  Path: BasicInfo.FILE_API + res.data.Result[i].cPath,
                  Order: res.data.Result[i].cOrder,                
                  cParentNm : "",
                  children: [],
                }
                this.items.push(item);           
                
                this.open.push(item.id);
              }              
            }

            for (let i = 0; i < res.data.Result.length; i++) {
              if (res.data.Result[i].iLev == 1) {                
                let item = {
                  id: res.data.Result[i].cId,
                  cMapGroupId: res.data.Result[i].cMapGroupId,
                  cBuildingId: res.data.Result[i].cBuildingId,
                  cMapId: res.data.Result[i].cMapId,
                  name: res.data.Result[i].cMapNm,
                  cMapFullNm: res.data.Result[i].cMapFullNm,
                  file: res.data.Result[i].cFileId,
                  iLev: res.data.Result[i].iLev,
                  Path: BasicInfo.FILE_API + res.data.Result[i].cPath,
                  Order: res.data.Result[i].cOrder,                
                  cParentNm : this.items[0].name,
                  children: [],
                }
                this.items[0].children.push(item);   
                this.open.push(item.id);                      
              }              
            }

            for (let i = 0; i < res.data.Result.length; i++) {
              if (res.data.Result[i].iLev == 2) {
                for (let j = 0; j < this.items[0].children.length; j++) {                  
                  if (this.items[0].children[j].cMapGroupId == res.data.Result[i].cMapGroupId) {     
                    let item = {
                      id: res.data.Result[i].cId,
                      cMapGroupId: res.data.Result[i].cMapGroupId,
                      cBuildingId: res.data.Result[i].cBuildingId,
                      cMapId: res.data.Result[i].cMapId,
                      name: res.data.Result[i].cMapNm,
                      cMapFullNm: res.data.Result[i].cMapFullNm,
                      file: res.data.Result[i].cFileId,
                      iLev: res.data.Result[i].iLev,
                      Path: BasicInfo.FILE_API + res.data.Result[i].cPath,
                      Order: res.data.Result[i].cOrder,                
                      cParentNm : this.items[0].children[j].name,
                      children: [],
                    }
                    this.items[0].children[j].children.push(item);
                    break;
                  }
                }
              }              
            }

            // for (let i = 0; i < res.data.Result.length; i++) {
            //   if (res.data.Result[i].iLev == 3) {
            //     for (let j = 0; j < this.items[0].children.length; j++) {    
            //       if (this.items[0].children[j].cMapGroupId == res.data.Result[i].cMapGroupId) {     
            //         for (let z = 0; z < this.items[0].children[j].children.length; z++) {    
            //           if (this.items[0].children[j].children[z].cBuildingId == res.data.Result[i].cBuildingId) {     
            //             let item = {
            //               id: res.data.Result[i].cId,
            //               cMapGroupId: res.data.Result[i].cMapGroupId,
            //               cBuildingId: res.data.Result[i].cBuildingId,
            //               cMapId: res.data.Result[i].cMapId,
            //               name: res.data.Result[i].cMapNm,
            //               cMapFullNm: res.data.Result[i].cMapFullNm,
            //               file: res.data.Result[i].cFileId,
            //               iLev: res.data.Result[i].iLev,
            //               Path: BasicInfo.FILE_API + res.data.Result[i].cPath,
            //               Order: res.data.Result[i].cOrder,           
            //               cParentNm : this.items[0].children[j].children[z].name,     
            //               children: [],
            //             }
            //             this.items[0].children[j].children[z].children.push(item);
            //             break;
            //           }
            //         }
            //       }
            //     }
            //   }              
            // }
            
            this.SetAll();            
            this.SetIni();
          } else {
            EventBus.$emit("onShowMsg", true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg", true, ""+err);
        });
    },

    show(zSel) {      
      this.zIniSel = zSel;
      this.Search();          
      this.bDialog = true;        
    },

    
    save() {      
      this.bDialog = false;      
      var cMapTreeNm = "";
      var cMapCds = "";

      this.zRealData = this.getData();
      
      for (let i = 0; i < this.zRealData.length; i++) {
        if (this.zRealData[i] == "000000") {
          // cMapTreeNm = "전체";
          // cMapCds = "000000";
          // break;
          //전체 맵 선택 불가!!          
          EventBus.$emit("onShowMsg",true, "유효하지 않은 맵선택입니다.\n(해당하는 구역별로 나누어 신청해 주시기 바랍니다.)");          
          return;
        }
        for (let j = 0; j < this.zResult.length;j++) {          
          if (this.zResult[j].cId == this.zRealData[i]) {
            if (cMapTreeNm != "" ) {
              cMapTreeNm = cMapTreeNm + ", ";
              cMapCds = cMapCds + ",";
            }
            cMapTreeNm = cMapTreeNm + this.zResult[j].cMapFullNm;
            cMapCds = cMapCds + this.zRealData[i];
            break;
          }          
        }
      }
      this.$emit("onGetMap", this.zRealData, cMapTreeNm, cMapCds);
    },

    SetAll(){
      this.zAllData = [];

      for (let i in this.items) {
        if (this.items[i].children.length == 0) {
          this.zAllData.push(this.items[i].id);
        } else {
          for (let j in this.items[i].children) {
            if (this.items[i].children[j].children.length == 0) {
              this.zAllData.push(this.items[i].children[j].id);
            }  else {              
              for (let k in this.items[i].children[j].children) {
                if (this.items[i].children[j].children[k].children.length == 0) {
                  this.zAllData.push(this.items[i].children[j].children[k].id);
                }  else {
                  for (let l in this.items[i].children[j].children[k].children) {                    
                    this.zAllData.push(this.items[i].children[j].children[k].children[l].id);                    
                  }
                }
              }
            }
          }
        }
      }
      // console.log("zAllData", this.zAllData);   
    },

    SetIni(){
      this.selection = [];
      for (let i in this.zIniSel){
        for (let j in this.zAllData){
          if (this.isAddCheck(this.zIniSel[i], this.zAllData[j])){
             this.selection.push(this.zAllData[j]);
          }
        }
      }
      // console.log("selection", this.selection);      
    },

    isAddCheck(org, data){
      if (org.substring(0,2) == "00") {
        return true;
      } else if (org.substring(2,4) == "00") {
        if (org.substring(0,2) == data.substring(0,2)) {
          return true;
        }        
      } else if (org.substring(4,6) == "00") {
        if (org.substring(0,4) == data.substring(0,4)) {
          return true;
        }        
      } else {
        if (org == data) {
          return true;
        }        
      }
      return false;
    },

    isCheckChild(data){
      // console.log(data, data.substring(4,6));
      if (data.substring(4,6) != "00"){
        return true;
      }
      return false;
    },
    getData() {          
      // console.log("selection", this.selection);      
      var zRealData = [];

      if (this.selection.length == this.zAllData.length) {
        zRealData.push("000000");
        return zRealData;
      }
      

      for (let i in this.items) {
        if (this.items[i].children.length != 0) {
          for (let j in this.items[i].children) {
            if (this.items[i].children[j].children.length != 0) {              
              for (let k in this.items[i].children[j].children) {
                if (this.items[i].children[j].children[k].children.length != 0) {                  
                  let iFindCount = 0; 
                  let zTemp = [];
                  for (let l in this.items[i].children[j].children[k].children) {                    
                    if (this.selection.includes(this.items[i].children[j].children[k].children[l].id)) {
                     iFindCount = iFindCount+1;                    
                     zTemp.push(this.items[i].children[j].children[k].children[l].id);
                    }
                  }

                  
                  if (iFindCount > 0) {
                    if (iFindCount == this.items[i].children[j].children[k].children.length){
                      zRealData.push(this.items[i].children[j].children[k].id);
                    } else {
                      for (let z in zTemp) {
                        zRealData.push(zTemp[z]);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }     

      for (let i in this.items) {
        if (this.items[i].children.length != 0) {
          for (let j in this.items[i].children) {
            
            if (this.items[i].children[j].children.length != 0) {                                        
              let iFindCount = 0; 
              let zTemp = [];
              for (let l in this.items[i].children[j].children) {                    
                if (this.selection.includes(this.items[i].children[j].children[l].id)) {                  
                  iFindCount = iFindCount+1;                    
                  zTemp.push(this.items[i].children[j].children[l].id);
                } else if (zRealData.includes(this.items[i].children[j].children[l].id)){                     
                  iFindCount = iFindCount+1;                                      
                }
              }              
              
              if (iFindCount > 0) {
                if (iFindCount == this.items[i].children[j].children.length){
                  for (let l in this.items[i].children[j].children) {     
                    for (let z in zRealData) {                                          
                      if (zRealData[z] == this.items[i].children[j].children[l].id){                     
                        zRealData.splice(z, 1);                                                
                      }
                    }
                  }                  
                  zRealData.push(this.items[i].children[j].id);
                } else {
                  for (let z in zTemp) {
                    zRealData.push(zTemp[z]);
                  }
                }
              }
            }
          }
        }
      }

      return zRealData;
    },   
  },
}
</script>